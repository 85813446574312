/* eslint no-console:0 */
import Rails from '@rails/ujs'
import * as ActiveStorage from "@rails/activestorage"
import 'jquery'
import 'popper.js'
import 'bootstrap'
import "bootstrap-icons/font/bootstrap-icons.css";
import "../stylesheets/application.scss";
require('@nathanvda/cocoon')
require('admin-lte');

import 'stylesheets/base'
import "@fortawesome/fontawesome-free/js/all";
import 'packs/text_message_login.js'
import 'stylesheets/index'
import 'packs/paid_button.js'

import "chartkick"
import "chart.js"
import 'packs/reports_management.js'
import 'packs/clients_management.js'
import 'packs/users_management.js'
import 'packs/system_management.js'
import 'packs/users_index.js'
import 'packs/clients_invoices.js'
import 'packs/admins_management.js'
import { Toast } from 'bootstrap';

// const images = require.context('../images', true)
const images = require.context('../images', true)
document.addEventListener("turbolinks:load", () => {
  $('[data-toggle="tooltip"]').tooltip()
});

document.addEventListener('DOMContentLoaded', () => {
  const toastElement = document.getElementById('toast-notification');
  if (toastElement && document.querySelector('#toast-notification .toast-body').textContent.trim() != '') {
    const toast = new Toast(toastElement);
    toast.show();
  }
});

Rails.start()
ActiveStorage.start()
