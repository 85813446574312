import { Toast } from 'bootstrap';

document.addEventListener("DOMContentLoaded", function() {
  const clientsManagement = document.querySelector(".clients_management");
  if (clientsManagement === null) {
    return
  };

  function transformScroll(event) {
    if (!event.deltaY) {
      return;
    }

    event.currentTarget.scrollLeft += event.deltaY + event.deltaX;
    event.preventDefault();
  }

  let elements = document.querySelectorAll('.scroll-container');
  elements.forEach(element => {
    element.addEventListener('mouseenter', () => {
        element.addEventListener('wheel', transformScroll);
    });
  });

  function showHideName(e) {
    let element = e.target;
    let newName = element.dataset.name;
    let oldName = element.textContent;
    element.textContent = newName;
    element.dataset.name = oldName;
    element.classList.toggle('min-width-200');
  }

  function handleTextTruncation(elements) {
    elements.forEach(element => {
        element.addEventListener('click', showHideName);
    });
  }

  const userNames = document.querySelectorAll('.show-user-name');
  const clientNames = document.querySelectorAll('.show-client-name');

  handleTextTruncation(userNames);
  handleTextTruncation(clientNames);

  const inputUser = document.querySelector('.user-search');
  const resultUsers = document.querySelector('.user-search-result');
  const clientInput = document.querySelector('.client-search');
  const clientResult = document.querySelector('.client-search-result');
  const authority = document.querySelector('#authority');
  const searchClientButton = document.querySelector('.search-client-button');

  searchClientButton.addEventListener('click', () => {
    let url = `/admin/clients/management_search`;
    const params = new URLSearchParams();

    if (inputUser.value && inputUser.value.trim() !== '') {
      if (inputUser.dataset.userId) params.append('user_id', inputUser.dataset.userId);
      params.append('user_query', inputUser.value);
    }

    if (clientInput.value && clientInput.value.trim() !== '') {
      if (clientInput.dataset.clientId) params.append('client_id', clientInput.dataset.clientId);
      params.append('client_query', clientInput.value);
    }

    params.append('authority', authority.value);
  
    window.location.href = `${url}?${params.toString()}`;
  });

  userOrClientSearchPopup(inputUser, fetchUsers);
  userOrClientSearchPopup(clientInput, fetchClients);
  hideOnBlur(inputUser, resultUsers);
  hideOnBlur(clientInput, clientResult);

  let timeoutId = null
  function userOrClientSearchPopup(inputElement, fetchFunction) {
    inputElement.addEventListener('input', (e) => {
      const searchText = e.target.value.trim();
  
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
  
      timeoutId = setTimeout(function() {
        fetchFunction(searchText);
      }, 500);
    });
  }

  function hideOnBlur(inputElement, resultElement) {
    inputElement.addEventListener('blur', (e) => {
      setTimeout(() => {resultElement.classList.add('d-none');}, 150);
    });
  }

  function fetchUsers(searchText) {
    fetch(`/admin/users/search?query=${searchText}`, {
      headers: {
        'X-Requested-With': 'XMLHttpRequest'
      }
    })
    .then(response => response.json())
    .then(users => {
      renderUsers(users);
    })
    .catch(error => {
      console.error('Error fetching users:', error);
    });
  }

  function renderUsers(users) {
    inputUser.dataset.userId = '';
    resultUsers.classList.remove('d-none');
    resultUsers.innerHTML = '';

    users.forEach(user => {
      const userElement = document.createElement('div');
      userElement.classList.add('user-result-item');
      let alias_name =  '';
      if (user.user_name_aliases.length > 0 && user.user_name_aliases[0].name !== '') {
        alias_name = `(${user.user_name_aliases[0].name}) `;
      }

      userElement.textContent = `${alias_name}${user.name || user.email}`;
      userElement.dataset.userId = user.id;

      userElement.addEventListener('click', function() {
        inputUser.value = user.name || user.email;
        inputUser.dataset.userId = user.id;

        inputUser.dataset.allData = JSON.stringify(user);
        resultUsers.innerHTML = ''; // Clear results after selection
      });

      resultUsers.appendChild(userElement);
    });
  }

  function fetchClients(searchText) {
    fetch(`/admin/clients/search?query=${searchText}`, {
      headers: {
        'X-Requested-With': 'XMLHttpRequest'
      }
    })
    .then(response => response.json())
    .then(clients => {
      renderClients(clients);
    })
    .catch(error => {
      console.error('Error fetching clients:', error);
    });
  }

  function renderClients(clients) {
    clientInput.dataset.clientId = '';
    clientResult.classList.remove('d-none');
    clientResult.innerHTML = '';

    clients.forEach(client => {
      const clientElement = document.createElement('div');
      clientElement.classList.add('client-result-item');
      clientElement.textContent = client.name;
      clientElement.dataset.clientId = client.id;

      clientElement.addEventListener('click', function() {
        clientInput.value = client.name;
        clientInput.dataset.clientId = client.id;
        clientResult.innerHTML = ''; // Clear results after selection
      });

      clientResult.appendChild(clientElement);
    });
  }

  const forms = document.querySelectorAll('.client-form');

  if (forms.length > 0) {
    forms.forEach(form => {
      form.addEventListener('ajax:success', (event) => {
        const [data, status, xhr] = event.detail;
        // Handle successful form submission

        document.querySelector('#toast-notification .toast-body').textContent = 'クライアントが正常に更新されました';
        const toastElement = document.getElementById('toast-notification');
        const toast = new Toast(toastElement);
        toast.show();
      });
  
      form.addEventListener('ajax:error', (event) => {
        const [data, status, xhr] = event.detail;

        document.querySelector('#toast-notification .toast-body').textContent = 'このクライアントは、マスター権限なしで御社と共有されていますので、クライアント設定を編集することはできません。';
        const toastElement = document.getElementById('toast-notification');
        const toast = new Toast(toastElement);
        toast.show();
      });
    })
  }
});
