import { Toast } from 'bootstrap';

document.addEventListener("DOMContentLoaded", function() {
  const clientsManagement = document.querySelector(".users_management");
  if (clientsManagement === null) {
    return
  };

  function transformScroll(event) {
    if (!event.deltaY) {
      return;
    }

    event.currentTarget.scrollLeft += event.deltaY + event.deltaX;
    event.preventDefault();
  }

  let elements = document.querySelectorAll('.scroll-container');
  elements.forEach(element => {
    element.addEventListener('mouseenter', () => {
        element.addEventListener('wheel', transformScroll);
    });
  });

  function showHideName(e) {
    let element = e.target;
    let newName = element.dataset.name;
    let oldName = element.textContent;
    element.textContent = newName;
    element.dataset.name = oldName;
    element.classList.toggle('min-width-200');
  }

  function handleTextTruncation(elements) {
    elements.forEach(element => {
        element.addEventListener('click', showHideName);
    });
  }

  const userNames = document.querySelectorAll('.show-user-name');

  handleTextTruncation(userNames);

  const inputUser = document.querySelector('.user-search');
  const resultUsers = document.querySelector('.user-search-result');
  const searchButton = document.querySelector('.search-button');

  searchButton.addEventListener('click', () => {
    let url = `/admin/users`;
    const params = new URLSearchParams();

    if (inputUser.value && inputUser.value.trim() !== '') {
      if (inputUser.dataset.userId) params.append('user_id', inputUser.dataset.userId);
      params.append('user_query', inputUser.value);
    }

    window.location.href = `${url}?${params.toString()}`;
  });

  userOrClientSearchPopup(inputUser, fetchUsers);
  hideOnBlur(inputUser, resultUsers);

  let timeoutId = null
  function userOrClientSearchPopup(inputElement, fetchFunction) {
    inputElement.addEventListener('input', (e) => {
      const searchText = e.target.value.trim();
  
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
  
      timeoutId = setTimeout(function() {
        fetchFunction(searchText);
      }, 500);
    });
  }

  function hideOnBlur(inputElement, resultElement) {
    inputElement.addEventListener('blur', (e) => {
      setTimeout(() => {resultElement.classList.add('d-none');}, 150);
    });
  }

  function fetchUsers(searchText) {
    fetch(`/admin/users/search?query=${searchText}&company_only=true`, {
      headers: {
        'X-Requested-With': 'XMLHttpRequest'
      }
    })
    .then(response => response.json())
    .then(users => {
      renderUsers(users);
    })
    .catch(error => {
      console.error('Error fetching users:', error);
    });
  }

  function renderUsers(users) {
    inputUser.dataset.userId = '';
    resultUsers.classList.remove('d-none');
    resultUsers.innerHTML = '';

    users.forEach(user => {
      const userElement = document.createElement('div');
      userElement.classList.add('user-result-item');
      let alias_name =  '';
      if (user.user_name_aliases.length > 0 && user.user_name_aliases[0].name !== '') {
        alias_name = `(${user.user_name_aliases[0].name}) `;
      }

      userElement.textContent = `${alias_name}${user.name || user.email}`;
      userElement.dataset.userId = user.id;

      userElement.addEventListener('click', function() {
        inputUser.value = user.name || user.email;
        inputUser.dataset.userId = user.id;

        inputUser.dataset.allData = JSON.stringify(user);
        resultUsers.innerHTML = ''; // Clear results after selection
      });

      resultUsers.appendChild(userElement);
    });
  }

  const forms = document.querySelectorAll('.user-form');

  if (forms.length > 0) {
    forms.forEach(form => {
      form.addEventListener('ajax:success', (event) => {
        const [data, status, xhr] = event.detail;
        // Handle successful form submission
        document.querySelector('#toast-notification .toast-body').textContent = 'ユーザーは正常に更新されました';
        const toastElement = document.getElementById('toast-notification');
        const toast = new Toast(toastElement);
        toast.show();
      });
  
      form.addEventListener('ajax:error', (event) => {
        const [data, status, xhr] = event.detail;
        // Handle failed form submission

        document.querySelector('#toast-notification .toast-body').textContent = 'ユーザーの更新に失敗しました';
        const toastElement = document.getElementById('toast-notification');
        const toast = new Toast(toastElement);
        toast.show();
      });
    })
  }
});
